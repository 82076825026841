import React from "react";
import { isEmptyBookReference } from "../../../helpers";
import BookReference from "../BookReference/BookReference";
// import { Link } from "gatsby";
// import isEmpty from "lodash/has";
// import reactHtmlParser from "react-html-parser";
// import { createSlug } from "../../../helpers";
import "./BookReferences.css";

const BookReferences = ({ book }) => {
  const { references, title } = book;
  if (!isEmptyBookReference({ references })) {
    return (
      <div className="BookReferences-container">
        <h4 className="BookReferences-title">
          Book References from{" "}
          <span className="BookReferences-title--source">{title}</span>
        </h4>
        <BookReference title={title} references={references} />
      </div>
    );
  }
  return null;
};

export default BookReferences;
