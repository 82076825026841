// Given a book-title, type: String, creates a url slug
/* eslint-disable import/prefer-default-export */
const createSlug = title => {
  if (typeof title === "string") {
    const slug = title
      .split(" ")
      .slice(0, 5)
      .map(word => word.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " "))
      .join("-")
      .toLocaleLowerCase();
    return slug;
  }
  return null;
};

const isEmptyBookReference = ({ references }) =>
  references.length === 1 &&
  references[0].refAuthor === "" &&
  references[0].refBook === "" &&
  references[0].refNotes === "";

module.exports = {
  createSlug,
  isEmptyBookReference
};
