import React from "react";
import has from "lodash/has";
import reactHtmlParser from "react-html-parser";
import "./Highlight.css";
import link from "./cappuccino.png";

const Highlight = ({ highlight }) => {
  const {
    note,
    text,
    highlightImage,
    noteImage,
    textBID,
    noteBID,
    location
  } = highlight;
  const highlightImageUrl =
    highlightImage &&
    has(highlightImage, "childImageSharp.fluid.originalImg") &&
    highlightImage.childImageSharp.fluid.originalImg;

  const noteImageUrl =
    noteImage &&
    has(noteImage, "childImageSharp.fluid.originalImg") &&
    noteImage.childImageSharp.fluid.originalImg;

  return (
    <div className="Highlight-container">
      <div className="Highlight-meta">
        Location: {location}{" "}
        {(text || highlightImageUrl) && textBID && (
          <a
            className="Highlight-link"
            name={textBID}
            href={`#${textBID}`}
            id={`#${textBID}`}
          >
            <img className="Highlight-linkImage" src={link} alt="link" />
          </a>
        )}
      </div>
      <div className="Highlight-text">
        {reactHtmlParser(text)}
        {highlightImageUrl && (
          <div className="Highlight-textImageContainer">
            <img
              className="Highlight-textImage"
              src={highlightImageUrl}
              alt="image from highlight or note"
            />
          </div>
        )}
      </div>
      <div className="Highlight-note">
        {(note || noteImageUrl) && noteBID && (
          <a
            className="Highlight-link"
            name={noteBID}
            href={`#${noteBID}`}
            id={`#${noteBID}`}
          >
            <img className="Highlight-linkImage--note" src={link} alt="link" />
          </a>
        )}
        {note &&
          reactHtmlParser(
            `<span class="Highlight-note--label">Note: </span> ${note}`
          )}
        <div className="Highlight-noteImageContainer">
          {noteImageUrl && (
            <img
              className="Highlight-noteImage"
              src={noteImageUrl}
              alt="image from highlight or note"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Highlight;
