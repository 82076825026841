import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout/MainLayout";
// import UserInfo from "../components/UserInfo/UserInfo";
import BookSummary from "../components/BookSummary/BookSummary";
import BookReferences from "../components/BookReferences/BookReferences";
import Highlight from "../components/Highlight/Highlight";
// import PostTags from "../components/PostTags/PostTags";
// import SocialLinks from "../components/SocialLinks/SocialLinks";
import config from "../../data/SiteConfig";
// import "./b16-tomorrow-dark.css";
import "./bookHighlights.css";

export default class BookHighlights extends React.Component {
  render() {
    const { data } = this.props.pageContext;
    const { title, highlights } = data;
    const hLength = highlights.length;
    const notesCnt = countNotes(highlights);
    // const postNode = this.props.data.markdownRemark;
    // const post = postNode.frontmatter;
    // if (!post.id) {
    //   post.id = slug;
    // }
    // if (!post.category_id) {
    //   post.category_id = config.postDefaultCategoryID;
    // }
    return (
      <Layout>
        <div className="app-wrapper">
          <Helmet>
            <title>{`${title} | ${config.siteTitle}`}</title>
          </Helmet>
          <div className="bookHighlights">
            <BookSummary book={data} />
            <div className="bookHighlights-summary">
              {hLength === 1
                ? `${hLength} Highlight `
                : `${hLength} Highlights `}{" "}
              |{notesCnt === 1 ? ` ${notesCnt} Note` : ` ${notesCnt} Notes`}
            </div>
            {parseHighlights(highlights)}
            <BookReferences book={data} />

            {/* <div dangerouslySetInnerHTML={{ __html: postNode.html }} /> */}
            {/* <div className="post-meta">
              <PostTags tags={post.tags} />
              <SocialLinks postPath={slug} postNode={postNode} />
            </div>
              <UserInfo config={config} /> */}
          </div>
        </div>
      </Layout>
    );
  }
}

function countNotes(highlights) {
  const hasNotes = highlights.filter(item => item.note != null);
  return hasNotes.length;
}

function parseHighlights(highlights) {
  return highlights.map((item, index) => (
    <li key={index} className="bookHighlights-list">
      <Highlight highlight={item} />
    </li>
  ));
}
