import React from "react";
import { Link } from "gatsby";

import { createSlug } from "../../../helpers";
import "./BookReference.css";

const BookReference = ({ references, title }) => {
  const slug = createSlug(title);
  const referenceList = references.map((reference, index) => {
    const { refAuthor, refBook, refNotes, refLink } = reference;
    const refTitle = refLink ? (
      <Link to={`/${refLink}`}>{refBook}</Link>
    ) : (
      `${refBook}`
    );
    return (
      <li className="BookReference-listItem" key={index}>
        <span className="BookReference-listItem--title">{refTitle}</span> by{" "}
        <span className="BookReference-listItem--author">{refAuthor}</span>
        <br />
        {refNotes !== "" && (
          <span className="BookReference-listItem--notes">
            Notes: {refNotes}
          </span>
        )}
      </li>
    );
  });

  return (
    <ul className="BookReference-list" key={title}>
      {referenceList}
      <div className="BookReference-source">
        Source: <Link to={`/${slug}`}>{title}</Link>
      </div>
    </ul>
  );
};

export default BookReference;
