import React from "react";
import { Link } from "gatsby";
import has from "lodash/has";
import reactHtmlParser from "react-html-parser";
import { createSlug } from "../../../helpers";
import "./BookSummary.css";

const BookSummary = ({ book, showNotesLink = false }) => {
  const {
    bookCoverURL,
    title,
    authors,
    dateRead,
    rating,
    asin,
    isbn10,
    isbn13,
    description,
    amazonLink,
    bookCoverImage
  } = book;

  const url = createSlug(title);

  const coverImgUrl =
    bookCoverImage && has(bookCoverImage, "childImageSharp.fluid.originalImg")
      ? bookCoverImage.childImageSharp.fluid.originalImg
      : bookCoverURL;

  return (
    <div className="bookSummary">
      <div className="bookSummary-cover">
        <Link to={`/${url}`} className="bookSummary-link">
          <figure>
            <img
              src={coverImgUrl}
              alt={`Cover of book ${title}`}
              className="bookSummary-coverImage"
            />
          </figure>
        </Link>
      </div>
      <div className="bookSummary-titleContents">
        <h3 className="bookSummary-title">
          <Link className="bookSummary-link" to={`/${url}`}>
            {title}
          </Link>
        </h3>
        <h4 className="bookSummary-authors">by: {authors.join(", ")}</h4>

        {showNotesLink && (
          <div className="bookSummary-notesLink">
            <Link className="bookSummary-link" to={`/${url}`}>
              Read my notes
            </Link>
          </div>
        )}

        <div className="bookSummary-metadata">
          <div>
            <p className="bookSummary-metadataItem">
              <span className="label">Date Read:</span> {dateRead}
            </p>
            <p className="bookSummary-metadataItem">
              <span className="bookSummary-metadataItem--label {">
                {" "}
                I rate this:
              </span>{" "}
              {rating}/10
            </p>
          </div>
          <div>
            {asin && (
              <p className="bookSummary-metadataItem">
                <span className="bookSummary-metadataItem--label {">ASIN:</span>{" "}
                {asin}
              </p>
            )}
            {isbn10 && (
              <p className="bookSummary-metadataItem">
                <span className="bookSummary-metadataItem--label {">
                  ISBN-10:
                </span>{" "}
                {isbn10}
              </p>
            )}
            {isbn13 && (
              <p className="bookSummary-metadataItem">
                <span className="bookSummary-metadataItem--label {">
                  ISBN-13:
                </span>{" "}
                {isbn13}
              </p>
            )}
          </div>
        </div>
        <div className="bookSummary-getBook">
          Check out the book on <a href={amazonLink}>Amazon</a> |{" "}
          <a
            href={`http://www.worldcat.org/title/${encodeURIComponent(title)}`}
          >
            your public library
          </a>
          .
        </div>

        <div className="bookSummary-description">
          {reactHtmlParser(description)}
        </div>
      </div>
    </div>
  );
};

export default BookSummary;
